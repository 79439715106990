import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Component({
  standalone: true,
  selector: '[appSwiper]',
  template: '<ng-content></ng-content>',
  providers: [],
  styles: [],
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;

  isServer = false;

  constructor(
    private el: ElementRef<SwiperContainer>,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  ngAfterViewInit(): void {
    if (!this.isServer) {
      Object.assign(this.el.nativeElement, this.config);

      this.el.nativeElement.initialize();
    }
  }
}
